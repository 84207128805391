import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Home() {
  //Fab button
  //const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className="home">
      <section id="section1">
        <div className="mt-8 lg:mt-0 lg:w-1/2 px-5 lg:px-6 xl:px-0 order-2 lg:order-1">
          <div className="frame1">
            <div className="title">Revolutionize Construction with AI</div>
            <div className="main-title">
              AI NATIVE <br />
              TECHNOLOGY
            </div>
            <div className="content">
              Building the Future Brick by Byte: Redefining Construction with
              AI's Precision, Innovation, and Sustainable Solutions, One Project
              at a Time
            </div>
            {/* temp change for booking a call
            <div className="button1">
              <div><Link to="/waiting">Join Waitlist</Link></div>
            </div>
            */}
          </div>
        </div>
        <div className="ml-auto w-3/4 lg:ml-0 lg:w-1/2 h-full order-1 lg:order-2">
          <img src="./img/home/ai.svg" className="h-full ml-auto" alt="ai" />
        </div>
      </section>
      <section id="section2">
        <div className="frame2">
          <div className="title">intro</div>
          <div className="main-title">INTRODUCING SITEGENIE</div>
          <div className="content">
            <div className="content-text">
              Sitegenie is a user-friendly AI platform designed specifically for
              the needs of small and medium-sized construction businesses. It
              seamlessly integrates with your existing workflows to boost
              efficiency and optimize project management.
              <br />
              <br />
              Here's how Sitegenie empowers your construction projects:
              <br />
              <br />
              Enhanced Planning & Scheduling Real-Time Progress Tracking
              Improved Communication
            </div>
            <div>
              <img
                src="./img/home/robot.svg"
                className="w-[434px]"
                alt="robot"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="section3">
        <div className="frame3">
          <div className="title">Services</div>
          <div className="main-title">WHY CHOOSE US</div>
          <div className="group1">
            <div className="group-img">
              <img src="./img/home/item1.svg" alt="item1" />
            </div>
            <div className="group-text">
              Enhance project planning and scheduling with AI-powered insights.
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center gap-y-12 mt-[65px]">
            <div className="group2">
              <div className="group-img">
                <img src="./img/home/item2.svg" alt="item2" />
              </div>
              <div className="group-text">
                Improve communication and collaboration across teams.
              </div>
            </div>
            <div className="group3">
              <div className="group-img">
                <img src="./img/home/item3.svg" alt="item3" />
              </div>
              <div className="group-text">
                Mitigate risks and identify potential delays proactively.
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center gap-y-12 mt-[103px]">
            <div className="group4">
              <div className="group-img">
                <img src="./img/home/item4.svg" alt="item4" />
              </div>
              <div className="group-text">
                Gain real-time data and progress updates on your projects.
              </div>
            </div>
            <div className="group5">
              <div className="group-img">
                <img src="./img/home/item5.svg" alt="item5" />
              </div>
              <div className="group-text">
                Optimize resource allocation and reduce costs.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section4">
        <div className="frame4">
          <div className="title">
            Take Your Construction Business to the Next Level
          </div>
          <div className="main-title">CONTACT US NOW</div>
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="text-group order-2 lg:order-1">
              <ul className="flex flex-col xl:justify-between gap-y-8 py-6 h-full">
                <li>
                  We understand the challenges of running an SME construction
                  business. Tight margins, complex projects, and keeping
                  everyone on the same page can be a daily struggle.
                </li>
                <li>
                  Our team of construction industry experts is here to help you
                  unlock the full potential of AI. Schedule a free consultation
                  to discuss your specific needs and see how [Your AI product
                  name] can transform your business
                </li>
              </ul>
            </div>
            <div className="img order-1 lg:order-2">
              <img src="./img/home/frame4-item.png" alt="frame4-img" />
              
              <div className="tablet">
                <div className="button1">
                  <div><Link to="/waiting">Join Waitlist</Link></div>
                </div>
                <div className="button2">
                  <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="desktop flex justify-between mt-[85px]">
            <div className="button1">
              <div><Link to="/waiting">Join Waitlist</Link></div>
            </div>
            <div className="button2">
              <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
            </div>
          </div>
          <div className="mobile">
            <div className="button1">
              <div><Link to="/waiting">Join Waitlist</Link></div>
            </div>
            <div className="button2">
              <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
            </div>
          </div>
            
        </div>
      </section>

      {/* FAB Button 
      <section id="sectionFab">
        <div className="fab" onClick={() => setModalVisible(true)}>+</div>
        {modalVisible && (
          <div className="modal" onClick={() => setModalVisible(false)}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
              <span className="close" onClick={() => setModalVisible(false)}>&times;</span>
              <p>Set a quick call here</p>
            </div>
          </div>
        )}
      </section>
      */}
    </div>
  );
}

export default Home;
