//import Map from "components/Map";
import React, { ChangeEvent } from 'react';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import {SITEGENIE_WAITLIST_URL} from '../helper'

function Contactus() {
  interface FormData {
    [key: string]: string;
  }

  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isFormValid = () => {
    return formData.firstName && formData.lastName && formData.email && formData.phone && formData.message;
  };

  const submitToAPI = () => {
    const data = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        contact_number: formData.phone,
        message: formData.message
    };

    axios.post(SITEGENIE_WAITLIST_URL, data)
        .then(response => {
            console.log(response.data);
            alert(response.data.message);
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
        submitToAPI(); 
        alert("Thank you for sending message for us!");
    } else {
        alert("Please fill in all the fields.");
    }
  };
  
  //Return to the top of Page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return <div className="contactus">
    <section id="section8">
      <div className="title">REACH OUT TO US</div>
      <div className="description">Contact us through any of the following</div>
          <div className="desktop flex justify-between mt-[85px]">
            <div className="button1">
              <div><Link to="/waiting">Join Waitlist</Link></div>
            </div>
            <div className="button2">
              <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
            </div>
          </div>
          <div className="tablet">
                <div className="button1">
                  <div><Link to="/waiting">Join Waitlist</Link></div>
                </div>
                <div className="button2">
                  <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
                </div>
          </div>
          <div className="mobile">
            <div className="button1">
              <div><Link to="/waiting">Join Waitlist</Link></div>
            </div>
            <div className="button2">
              <div><a href="https://app.apollo.io/#/meet/sitegenie_ai/15-min">Schedule a quick call</a></div>
            </div>
          </div>
      <div className="content">
        <div className="item">
          <div className="item-key">Address</div>
          <div className="item-value">7100 Woodbine Ave, Markham, ON L3R 5J2</div>
        </div>
        <div className="item">
          <div className="item-key">Phone:</div>
          <div className="item-value">+1 647-655-8909</div>
        </div>
        <div className="item">
          <div className="item-key">Email:</div>
          <div className="item-value">Support@Sitegenie.ai</div>
        </div>
      </div>
    </section>
    {/*<Map center={[51.505, -0.09]} zoom={13} />*/}
    <div className="contactus-map-section">
      <iframe title="contactus-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5757.868134123581!2d-79.3499879!3d43.815726999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d36f724a7e19%3A0xae1923301b7e2f80!2s7100%20Woodbine%20Ave%2C%20Markham%2C%20ON%20L3R%205J2!5e0!3m2!1sen!2sca!4v1715977201352!5m2!1sen!2sca" loading="lazy"></iframe>
    </div>
    <section id="section9">
      <div className="description">Or send us a message</div>
      <form onSubmit={handleSubmit}>
        <div className="flex mt-[25px]">
          <div className="w-1/2 pr-[10px]">
            <div className="label">First name</div>
            <div className="inputbox">
              <input type="text" name="firstName" onChange={handleChange} value={formData.firstName}/>
            </div>
          </div>
          <div className="w-1/2 pl-[10px]">
            <div className="label">Surname</div>
            <div className="inputbox">
              <input type="text" name="lastName" onChange={handleChange} value={formData.lastName}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Email</div>
            <div className="inputbox">
              <input type="email" name="email" onChange={handleChange} value={formData.email}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Phone number</div>
            <div className="inputbox">
              <input type="tel" name="phone" onChange={handleChange} value={formData.phone}/>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="w-full">
          <div className="label">Message</div>
            <div className="inputbox">
              <input name="message" onChange={handleChange} value={formData.message}/>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[25px]">
          <div className="button">
            <div className="button-text">
              <button type="submit" disabled={!isFormValid()}>Send message</button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>;
}

export default Contactus;
